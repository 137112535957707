import React from "react";

const Key = "49af8e6e8dmsh0f9f2074dabddcbp1a7445jsnedd6de2d5aa8";
const REACT_APP_ACCESS_KEY = "FuLLojD7_s9TYS5GLroMrjM0i9PuAE9S4vNwfaJwDI0";
const REACT_APP_SECRET_KEY = "12LMDDg6AFmMUV1ovU674clJ5K5cc2wfHZzwNKzw8Xc";

export default {
  Key: Key,
  UnsplashKey: REACT_APP_ACCESS_KEY,
  SecretKey: REACT_APP_SECRET_KEY,
};
